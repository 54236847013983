import { useStaticQuery, graphql } from 'gatsby'
export default () => {
  const { allWordpressAcfOptions: { edges } } = useStaticQuery(
    graphql`
      query Options {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                facebookLink: facebook_link
                youtubeLink: youtube_link
                linkedInLink: linkedin_link
                instagramLink: instagram_link
                columns {
                  content
                }
                columns_english {
                  header
                  content
                }
              }
            }
          }
        }
      }
    `
  )
  return edges ? edges[0].node.options : {}
}
