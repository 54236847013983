import React, {
  useState,
  createRef,
  useEffect,
  Fragment,
  useContext
} from 'react'
import Link from '../Link'
import { Text, LogoWithRef, TextWithRef } from '../Logo'
import MobileIcons from '../MobileIcons'
import { get } from 'lodash'
import classnames from 'classnames'
import ScrollMagic from 'scrollmagic'
import 'animation.gsap'
import { TweenMax, TimelineMax } from 'gsap'
import AnimateHeight from 'react-animate-height'
import styles from './TopBar.module.scss'
import mobileStyles from './Mobile.module.scss'

import { useArrayOfValues } from '../../hooks'
import { LanguageContext } from '../../context'
import { getLinkUrl } from '../../utils'
import SearchInput from '../SearchInput/SearchInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faSearch, faLock } from '@fortawesome/pro-light-svg-icons'
import { TRANSLATIONS } from '../../misc/translations'

let timer = null

export default ({ data, menuIsClicked, setMenuIsClicked }) => {
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [searchOpen, setSearchOpen] = useState(false)
  const arrayOfValues = useArrayOfValues()
  const [menuActive, setMenuActive] = useState(false)

  const topBarRef = createRef()
  const topBarContainerRef = createRef()
  const logoRef = createRef()
  const logoTextRef = createRef()

  const { currentLang: language, otherPost } = useContext(LanguageContext)
  const newLanguage = language === 'is' ? 'EN' : 'IS'
  const logoLink = language === 'is' ? '/' : '/en'
  const otherLangUrl =
    otherPost?.hasPost || otherPost?.slug
      ? otherPost.slug
      : `/${newLanguage.toLowerCase()}`

  useEffect(() => {
    if (menuActive) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }

    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [menuActive])

  useEffect(() => {
    const handleResize = () => {
      if (document.body.clientWidth > 1100) {
        setMenuActive(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const controller = new ScrollMagic.Controller()
    const tweenText = new TimelineMax()

    const tweenBackground = TweenMax.to(topBarRef.current, 1, {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    })

    const tweenContainer = TweenMax.to(topBarContainerRef.current, 1, {
      height: 80,
    })

    const options = {
      triggerHook: 0,
    }

    new ScrollMagic.Scene({ ...options, duration: '10%' })
      .setTween(tweenContainer)
      .addTo(controller)
    new ScrollMagic.Scene({ ...options, duration: '40%' })
      .setTween(tweenBackground)
      .addTo(controller)
    new ScrollMagic.Scene({ triggerHook: 1, offset: 40 })
      .setTween(tweenText)
      .addTo(controller)
  }, [])

  const items =
    language === 'is'
      ? get(data, 'menu.edges[0].node.items', [])
      : get(data, 'enMenu.edges[0].node.items', [])

  const handleMouseEnter = itemIdx => {
    clearTimeout(timer)
    setActiveDropdown(itemIdx)
  }

  const onMenuClick = (e, itemIdx) => {
    e.stopPropagation()
    setMenuIsClicked(itemIdx)
  }
  useEffect(() => {
    if (!menuIsClicked && menuIsClicked !== 0) {
      setActiveDropdown(null)
    }
  }, [menuIsClicked])

  const iconLinks =
    language === 'is'
      ? get(data, 'isIconLinks.acf.sections_page[0].link_bubble', [])
      : get(data, 'enIconLinks.acf.sections_page[0].link_bubble', [])

  const handleMouseLeave = itemIdx => {
    if ((!menuIsClicked && menuIsClicked !== 0) || menuIsClicked !== itemIdx) {
      timer = setTimeout(() => {
        if (activeDropdown === itemIdx) {
          setActiveDropdown(null)
        }
      }, 600)
    }
  }

  return (
    <Fragment>
      <div className={mobileStyles.mobileTopBar}>
        <div className={mobileStyles.container}>
          <div className={mobileStyles.background} />
          <Link to={logoLink} className={mobileStyles.logoContainer}>
            <LogoWithRef className={mobileStyles.logo} />
            <Text />
          </Link>
          <span className="d-flex">
            <Link
              to='https://thjonustuvefur.ljosleidarinn.is'
              role="button"
              className={classnames(mobileStyles.language, {
                [mobileStyles.languageActive]: menuActive,
              })}>
              <FontAwesomeIcon icon={faLock} className="ml-2" />
            </Link>
            <Link
              role="button"
              onClick={() => (window.location.href = otherLangUrl)}
              className={classnames(mobileStyles.language, {
                [mobileStyles.languageActive]: menuActive,
              })}
            >
              {newLanguage}
              <FontAwesomeIcon icon={faGlobe} className="ml-2" />
            </Link>
            <button
              onClick={() => setMenuActive(!menuActive)}
              className={classnames(mobileStyles.burger, {
                [mobileStyles.burgerActive]: menuActive,
              })}
            >
              <i className={mobileStyles.burgerBars} />
            </button>
          </span>
        </div>
      </div>
      <div
        className={classnames(mobileStyles.mobileMenu, {
          [mobileStyles.mobileMenuActive]: menuActive,
        })}
      >
        <MobileIcons items={iconLinks} />
        <ul className={mobileStyles.navigation}>
          {items.map((item, itemIdx) => {
            const children = item.wordpress_children

            return (
              /* eslint-disable-next-line */
              <li key={itemIdx} onClick={() => arrayOfValues.toggle(itemIdx)}>
                <div className={mobileStyles.linkContainer}>
                  <div className={mobileStyles.not_link}>{item.title}</div>
                  {children && (
                    <div
                      className={classnames(mobileStyles.caret, {
                        [mobileStyles.caretActive]: arrayOfValues.has(itemIdx),
                      })}
                    />
                  )}
                </div>
                <AnimateHeight
                  duration={300}
                  height={arrayOfValues.has(itemIdx) ? 'auto' : '0'}
                >
                  {children && (
                    <ul className={mobileStyles.subNavigation}>
                      {item.wordpress_children.map((child, childIdx) => {
                        const delay = `${childIdx * 0.5}s`
                        return (
                          <li key={childIdx} style={{ transitionDelay: delay }}>
                            <Link to={getLinkUrl(child)}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: child.title,
                                }}
                              />
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </AnimateHeight>
              </li>
            )
          })}
        </ul>
        <div className={mobileStyles.mobileSearch}>
          <SearchInput
            searchPage={TRANSLATIONS[language].searchPage}
            placeholder={TRANSLATIONS[language].searchInputPlaceholder}
          />
        </div>
        <button
          tag="button"
          to={`/get-eg-tengst/`}
          className={classnames('mt-4 button-link', mobileStyles.button)}
        >
          <span className="ss-flash pr-3" />
          <span>Get ég tengst?</span>
        </button>
      </div>

      <div ref={topBarRef} className={styles.topBar}>
        <div ref={topBarContainerRef} className={styles.topBarContainer}>
          <Link to={logoLink} className={styles.logoContainer}>
            <LogoWithRef idx={2} ref={logoRef} />
            <TextWithRef className={styles.logoText} ref={logoTextRef} />
          </Link>
          <ul className={styles.navigation}>
            <div
              className={classnames(styles.search, {
                [styles.searchOpen]: searchOpen,
              })}
            >
              <SearchInput
                open={searchOpen}
                onClickIcon={() => setSearchOpen(false)}
                searchPage={TRANSLATIONS[language].searchPage}
                placeholder={TRANSLATIONS[language].searchInputPlaceholder}
              />
            </div>
            {items.map((item, itemIdx) => {
              const childClassNames = classnames(styles.dropdown, {
                [styles.dropdownVisible]: activeDropdown === itemIdx,
              })

              return (
                /* eslint-disable-next-line */
                <li
                  key={itemIdx}
                  onMouseEnter={() => handleMouseEnter(itemIdx)}
                  onMouseLeave={() => handleMouseLeave(itemIdx)}
                  onClick={e => onMenuClick(e, itemIdx)}
                >
                  <div className={styles.not_link}>{item.title}</div>
                  {item.wordpress_children && (
                    <ul className={childClassNames}>
                      {item.wordpress_children.map((child, childIdx) => {
                        const delay = `${childIdx * 0.5}s`

                        return (
                          <li key={childIdx} style={{ transitionDelay: delay }}>
                            <Link to={getLinkUrl(child)}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: child.title,
                                }}
                              />
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>

          <ul className={styles.navigation}>
            <li>
              <button
                className="remove-generic-styles"
                onClick={() => {
                  setSearchOpen(true)
                }}
              >
                Leit
                <FontAwesomeIcon icon={faSearch} className="ml-2" />
              </button>
            </li>
            <li>
              <a href={otherLangUrl}>
                {newLanguage}
                <FontAwesomeIcon icon={faGlobe} className="ml-2" />
              </a>
            </li>
            <li>
              <a href="https://thjonustuvefur.ljosleidarinn.is">
                Þjónustuvefur
                <FontAwesomeIcon icon={faLock} className="ml-2" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  )
}
