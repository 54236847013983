import React from 'react'
import { Link } from 'gatsby'

const fixUrl = (url = '') => {
  if (url.match(/^\/|^http/)) {
    return url
  }

  if (!url.match(/#/)) {
    return `/${url}`
  }

  return url
}

export default ({ to: originalTo, href, children, ...props }) => {
  const to = fixUrl(originalTo || href)

  if (!to.match(/^\//)) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  )
}
