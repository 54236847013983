import { useState, useEffect} from 'react'

export default arr => {
  const [ values, setValues ] = useState(arr || [])

  const add = value => {
    if (values.indexOf(value) === -1) {
      setValues([
        ...values,
        value
      ])
    }
  }

  const toggle = value => has(value) ? remove(value) : add(value)

  const has = value => values.indexOf(value) !== -1

  const remove = value => {
    const valueIndex = values.indexOf(value)
    const newValues = values.filter((_, index) => index !== valueIndex)
    setValues(newValues)
  }

  const list = () => values

  return {
    add,
    remove,
    toggle,
    has,
    list
  }
}
