import React, { Fragment } from 'react'
import classnames from 'classnames'
import styles from './MobileIcons.module.scss'
import Link from '../Link'

export default (icons) => {
  return (
    <div className={styles.container}>
      {icons.items.map((x, index) => {
        const classKey = `icon${x.key.charAt(0).toUpperCase() + x.key.slice(1)}`
        const transitionDelay = `${index * 0.2}s`
        const icon = require(`../../images/mobile/${x.key}.svg`)

        const content = (
          <Fragment>
            <img src={icon} />
            <span dangerouslySetInnerHTML={{ __html: x.title }} />
          </Fragment>
        )

        if (x.external) {
          return (
            <Link
              key={index}
              href={x.link || '#'}
              className={classnames(styles.icon, styles[classKey])}
              style={{ transitionDelay }}
              target='_blank'
              rel='noopener noreferrer'
            >
              {content}
            </Link>
          )
        }

        return (
          <Link
            key={index}
            to={x.link || '#'}
            className={classnames(styles.icon, styles[classKey])}
            style={{ transitionDelay }}
          >
            {content}
          </Link>
        )
      })}
    </div>
  )
}
