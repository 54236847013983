import React, { useEffect, useState, useRef } from 'react'
import Search from '../../components/Svg/search.js'

const SearchInput = ({
  initialValue,
  onSubmit = null,
  placeholder = 'Leit á vefnum',
  searchPage = '/leit',
  open = false,
  onClickIcon = () => {}
}) => {
  const [input, setInput] = useState(initialValue)
  const searchInputRef = useRef(null);
  const searchSubmit = e => {
    e.preventDefault()
    if (onSubmit !== null) {
      onSubmit(input)
    } else {
      handleSearchSubmit(input, e)
    }
  }

  const handleSearchSubmit = (searchString, e) => {
    e.preventDefault()
    const action = e.target.getAttribute('action')
    const newLocation = searchString
      ? action + '/?q=' + searchString
      : searchString
    window.location.replace(newLocation)
  }
  useEffect(() => {
    searchInputRef.current.focus()
  }, [open])

  return (
    <form
      className='input-search'
      action={searchPage}
      method='post'
      id='footer-search-form'
      acceptCharset='UTF-8'
      onSubmit={searchSubmit}
    >
      <input
        type='text'
        ref={searchInputRef}
        placeholder={placeholder}
        className='input-shadow'
        value={input}
        onChange={e => setInput(e.target.value)}
      />
      <span className='search-icon' onClick={onClickIcon}>
        {open ? <i name='X icon' /> :
        <Search />}
      </span>
    </form>
  )
}

export default SearchInput
