import React, { useContext } from 'react'
import Link from '../Link'
import classNames from 'classnames'
import { useOptionsData } from '../../hooks'
import { LanguageContext } from '../../context'
import { TRANSLATIONS } from '../../misc/translations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faYoutubeSquare,
  faLinkedin,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'
export default () => {
  const { currentLang } = useContext(LanguageContext)
  const {
    facebookLink,
    linkedInLink,
    youtubeLink,
    instagramLink,
    columns,
    columns_english: columnsEnglish,
  } = useOptionsData()
  const cols = currentLang === 'is' ? columns : columnsEnglish
  return (
    <footer className="footer-main">
      <div className="container">
        <div className="row justify-content-between">
          {cols &&
            cols.map((col, i) => {
              return (
                <div
                  key={i}
                  className={classNames(
                    'col-sm-6 vspace-m-bottom',
                    { 'col-md-2': i < 1 },
                    { 'col-md-3': i >= 1 }
                  )}
                >
                  <div dangerouslySetInnerHTML={{ __html: col.content }} />
                </div>
              )
            })}
          {facebookLink && (
            <div className="col-md-2 col-sm-6 vspace-m-bottom">
              <ul>
                <li>{TRANSLATIONS[currentLang].footerSocialTitle}</li>
                <li className="footer-social d-lg-flex justify-content-between">
                  <Link href={facebookLink}>
                    <FontAwesomeIcon
                      icon={faFacebookSquare}
                      className="m-lg-0"
                    />
                  </Link>
                  <Link href={youtubeLink}>
                    <FontAwesomeIcon
                      icon={faYoutubeSquare}
                      className="m-lg-0"
                    />
                  </Link>
                  <Link href={linkedInLink}>
                    <FontAwesomeIcon icon={faLinkedin} className="m-lg-0" />
                  </Link>
                  <Link href={instagramLink}>
                    <FontAwesomeIcon
                      icon={faInstagramSquare}
                      className="m-lg-0"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
}
