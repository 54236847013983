import React, { Component } from 'react'
import Cmp from './TopBar.js'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            isIconLinks: wordpressPage(title: { eq: "Forsíða" }) {
              slug
              title
              content
              wordpress_id
              acf {
                sections_page {
                  __typename
                  ... on WordPressAcf_frontpage_nav_icons {
                    link_bubble {
                      isexternal
                      key
                      link
                      title
                    }
                  }
                }
              }
            }
            enIconLinks: wordpressPage(title: { eq: "Frontpage" }) {
              slug
              title
              content
              wordpress_id
              acf {
                sections_page {
                  __typename
                  ... on WordPressAcf_frontpage_nav_icons {
                    link_bubble {
                      isexternal
                      key
                      link
                      title
                    }
                  }
                }
              }
            }
            menu: allWordpressWpApiMenusMenusItems(filter: {name: {eq: "aðalvalmynd"}}) {
              edges {
                node {
                  name
                  items {
                    object_slug
                    title
                    type
                    object
                    object_id
                    url
                    wordpress_children {
                      title
                      object
                      object_slug
                      object_id
                      url
                    }
                  }
                }
              }
            }
            enMenu: allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Main Menu"}}) {
              edges {
                node {
                  name
                  items {
                    object_slug
                    title
                    type
                    object
                    object_id
                    url
                    wordpress_children {
                      title
                      object
                      object_slug
                      object_id
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Cmp data={data} {...this.props} />}
      />
    )
  }
}

export default Connected
