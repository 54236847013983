import React, { useContext } from 'react'
import styles from './LiveChatIcon.module.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/pro-light-svg-icons'
import { LanguageContext } from '../../context'

const LiveChatIcon = ({ onClick, active }) => {
  const { currentLang: language } = useContext(LanguageContext)
  const currLang = language === 'is' ? 'Netspjall' : 'Live chat'

  const headlineClass = classNames('mt-1', 'mb-0', styles.headline)
  const containerClass = classNames(
    styles.container,
    'd-none',
    'd-lg-flex',
    'flex-column',
    'justify-content-center',
    'align-items-center',
    { [styles.inactive]: !active }
  )

  return (
    <div role='navigation'>
      <button className={containerClass} onClick={onClick}>
        <FontAwesomeIcon className={styles.icon} icon={faComments} title='live chat' />
        <h2 className={headlineClass}>{currLang}</h2>
      </button>
    </div>
  )
}

export default LiveChatIcon
